* {
  font-family: "Poppins", sans-serif !important;
}
.css-13cymwt-control {
  padding: 2px 0;
  border-radius: 6px !important;
}
.info-title {
  font-size: 12px;
  margin-left: 4px;
}
.info-title-error {
  font-size: 12px;
  margin-left: 4px;
  color: #ae0909 !important;
}
.profil-input {
  padding: 0 !important;
  height: 42px !important;
}
input[type="file"] {
  border-radius: 8px;
}
.form-control-error {
  border: 0.1px solid #d32f2f !important;
  display: block;
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6;
  color: #4f4f4f;
  background-color: #fff;
  background-clip: padding-box;
  appearance: none;
  border-radius: 0.25rem;
  min-height: auto;
  transition: all 0.1s linear;
  padding: 3.5px;
}
.form-control-error[type="file"] {
  overflow: hidden;
}
.form-control-error[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.optional-status {
  border: 1px solid red !important;
  border-radius: 8px !important;
}

input[type="file"]::file-selector-button {
  margin-right: 20px;
  margin-left: 0;
  font-weight: 500;
  border: none;
  background-image: linear-gradient(
    to right,
    #3b74d7,
    rgb(221, 56, 27)
  ) !important;
  padding: 15px 24px;
  border-radius: 8px;
  color: #fff;
  cursor: pointer;
  transition: 0.2s;
}
