:root {
  --white: #fff;
  --black: #000;
  --dark: #1e1e1e;
  --gray: rgba(1, 1, 1, 0.6);
  --lite: rgba(255, 255, 255, 0.6);
  --primary: #234c77;
  --secondary: #fdc632;
}
body {
  margin: 0;
  overflow-x: hidden !important;
  font-family: "Poppins", sans-serif;
}

a {
  text-decoration: none !important;
  min-width: fit-content;
  width: fit-content;
  width: -moz-fit-content;
}

a,
button {
  transition: 0.5s;
}

a,
p {
  font-size: 14px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--primary);
  font-weight: 600;
}

a,
button,
input,
textarea,
select {
  outline: none !important;
}

fieldset {
  border: 0;
}

.title {
  color: var(--primary);
}

.flex,
.fixed_flex {
  display: flex;
}

.flex-content {
  width: 100%;
}

.padding_1x {
  padding: 1rem;
}

.padding_2x {
  padding: 2rem;
}

.padding_3x {
  padding: 3rem;
}

.padding_4x {
  padding: 4rem;
}

.btn {
  padding: 0.8rem 2rem;
  border-radius: 5px;
  text-align: center;
  font-weight: 500;
  text-transform: uppercase;
}

.btn_1 {
  border: 1px solid var(--primary);
  background-color: var(--primary);
  color: var(--secondary);
}

.btn_1:hover {
  background-color: transparent;
  color: var(--primary);
}

.btn_2 {
  border: 1px solid var(--secondary);
  background-color: var(--secondary);
  color: var(--primary);
  height: 56px;
}

.btn_2:hover {
  border: 1px solid var(--secondary);
  background-color: var(--secondary);
  color: var(--secondary);
}

@media (max-width: 920px) {
  .flex {
    flex-wrap: wrap;
  }

  .padding_1x,
  .padding_2x,
  .padding_3x,
  .padding_4x {
    padding: 1rem;
  }

  .btn {
    padding: 0.5rem 1rem;
  }

  a,
  p {
    font-size: 12px;
  }
}

/***************************
               FOOTER
****************************/
footer {
  background-color: var(--primary);
  color: var(--lite);
}

footer h3 {
  color: var(--white);
  margin-bottom: 1.5rem;
}

footer a {
  color: var(--lite);
  display: block;
  margin: 15px 0;
}

footer a:hover {
  color: var(--white);
}

footer fieldset {
  padding: 0;
}

footer fieldset input {
  background-color: #334f6c;
  border: 0;
  color: var(--lite);
  padding: 1rem;
}

footer fieldset .btn {
  border-radius: 0;
  border: 0;
}

footer fieldset .btn_2:hover {
  background-color: var(--secondary);
  border: 0;
  color: var(--primary);
}

footer .flex:last-child {
  align-items: center;
}

footer .flex:last-child .flex-content:last-child {
  text-align: right;
}

footer .flex:last-child p {
  color: var(--white);
}

footer .flex:last-child a {
  width: 40px;
  display: inline-block;
  background-color: #334f6c;
  color: var(--white);
  padding: 0.5rem;
  margin-right: 3px;
  text-align: center;
}

footer .flex:last-child a:hover {
  background-color: var(--secondary);
  color: var(--gray);
}

@media (max-width: 1100px) {
  footer .flex:first-child {
    flex-wrap: wrap;
  }

  footer .flex:first-child .flex-content {
    flex: 1 1 40%;
  }
}

@media (max-width: 920px) {
  footer .flex:last-child .flex-content:last-child {
    text-align: left;
  }
}

@media (max-width: 320px) {
  footer .flex:first-child .flex-content {
    flex: 1 1 100%;
  }
}
.card-scale {
  transition: 0.3s;
  border-radius: 12px !important;
}

.card-scale:hover {
  transform: scale(1.04);
}

.each-type-box {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  min-width: 320px;
  min-height: 240px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: 0.2s;
  cursor: pointer;
}
.child-box {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.each-type-box:hover {
  transform: scale(1.03);
}
.flexer-box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 60px;
  margin: 24px 0;
}
.main-title {
  font-weight: 600 !important;
  text-align: center;
  font-size: 36px !important;
  line-height: 24px;
  color: #234c77 !important;
}
.font-box {
  font-size: 30px !important;
  font-weight: 400 !important;
}
@media screen and (max-width: 460px) {
  .flexer-box {
    margin: 10px 0;
  }
  .main-title {
    font-size: 27px !important;
  }
  .font-box {
    font-size: 25px !important;
  }
}
@media screen and (max-width: 400px) {
  .flexer-box {
    margin: 10px 0;
  }
  .main-title {
    font-size: 26px !important;
  }
  .font-box {
    font-size: 24px !important;
  }
}
@media screen and (max-width: 376px) {
  .flexer-box {
    margin: 10px 0;
  }
  .main-title {
    font-size: 25px !important;
  }
  .font-box {
    font-size: 23px !important;
  }
}
