.card-scale {
  transition: 0.3s;
  border-radius: 12px !important;
}

.card-scale:hover {
  transform: scale(1.04);
}

.each-type-box {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  min-width: 320px;
  min-height: 240px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: 0.2s;
  cursor: pointer;
}
.child-box {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.each-type-box:hover {
  transform: scale(1.03);
}
.flexer-box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 60px;
  margin: 24px 0;
}
.main-title {
  font-weight: 600 !important;
  text-align: center;
  font-size: 36px !important;
  line-height: 24px;
  color: #234c77 !important;
}
.font-box {
  font-size: 22px !important;
  font-weight: 400 !important;
}
@media screen and (max-width: 460px) {
  .flexer-box {
    margin: 10px 0;
  }
  .main-title {
    font-size: 27px !important;
  }
  .font-box {
    font-size: 20px !important;
  }
}
@media screen and (max-width: 400px) {
  .flexer-box {
    margin: 10px 0;
  }
  .main-title {
    font-size: 26px !important;
  }
  .font-box {
    font-size: 18px !important;
  }
}
@media screen and (max-width: 376px) {
  .flexer-box {
    margin: 10px 0;
  }
  .main-title {
    font-size: 25px !important;
  }
  .font-box {
    font-size: 16px !important;
  }
}
