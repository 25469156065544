.image-user {
  border-radius: 50%;
  height: 300px;
  width: 300px !important;
  box-shadow: rgba(67, 71, 85, 0.27) 0 0 0.25em,
    rgba(90, 125, 188, 0.05) 0 0.25em 1em;
}
.box {
  background: #f7f7f7;
  padding: 8px 16px;
  border-radius: 8px;
}
.box > span {
  font-size: 13px;
}
.btn_edit {
  position: fixed !important;
  bottom: 54px;
  right: 54px;
}
.icon_edit {
  font-size: 38px !important;
}

@media (max-width: 391px) {
  .btn_edit {
    bottom: 42px;
    right: 38px;
  }
  .icon_edit {
    font-size: 34px !important;
  }
  .image-user {
    border-radius: 8px;
    height: 240px;
    box-shadow: rgba(67, 71, 85, 0.27) 0 0 0.25em,
      rgba(90, 125, 188, 0.05) 0 0.25em 1em;
  }
}
@media (min-width: 392px) and (max-width: 776px) {
  .btn_edit {
    bottom: 46px;
    right: 40px;
  }
  .image-user {
    border-radius: 8px;
    height: 260px;
    box-shadow: rgba(67, 71, 85, 0.27) 0 0 0.25em,
      rgba(90, 125, 188, 0.05) 0 0.25em 1em;
  }
  .icon_edit {
    font-size: 35px !important;
  }
}
@media (min-width: 777px) and (max-width: 992px) {
  .btn_edit {
    bottom: 52px;
    right: 50px;
  }
  .icon_edit {
    font-size: 40px !important;
  }
  .image-user {
    border-radius: 8px;
    height: 280px;
    box-shadow: rgba(67, 71, 85, 0.27) 0 0 0.25em,
      rgba(90, 125, 188, 0.05) 0 0.25em 1em;
  }
}
