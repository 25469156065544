.translate-dropdown{
    position: absolute;
    will-change: transform;
    top: 3px !important;
    left: 0;
    transform: translate3d(0px, 36px, 0px);
}
.translate-dropdown-black{
    position: absolute;
    will-change: transform;
    top: 3px !important;
    border: 0.1px solid #aeabab !important;
    left: 0;
    transform: translate3d(0px, 36px, 0px);
}
.dropdown-menu {
    min-width:105px !important;
    border-radius: 8px !important;
}
.btn-translate{
    padding: 8px 14px !important;
    background: #ededed !important;
}
.btn-translate-black{
    padding: 8px 14px !important;
    background: #e4e4e4 !important;
}
.text-translate{
    font-size: 12px;
}
.dropdown-item{
    transition: .1s;
}
.dropdown-item:hover{
    border-radius: 8px !important;

}