.number {
  font-size: 16px !important;
}

.footer_disable {
  display: flex;
}
@media screen and (max-width: 510px) {
  .footer_disable {
    display: none;
  }
}
