.rounded-t-5 {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}
.number-showing {
  margin-top: 3px;
  font-size: 14px !important;
}
@media (max-width: 550px) {
  .rounded-tr-lg-0 {
    border-top-right-radius: 0.5rem;
    border-bottom-left-radius: 0rem;
  }
}

.wrapper-word {
  display: inline-block;
  font-family: "arial";
}

.typed {
  font-size: 36px !important;
  margin: 36px 0 !important;
  overflow: hidden;
  white-space: nowrap;
  border-right: 2px solid;
  font-weight: 500 !important;
  width: 0;
  animation: typing 8s forwards infinite;
  color: #0054f9;
}

@keyframes typing {
  0% {
    width: 0;
  }
  50% {
    width: 100%;
  }
  100% {
    width: 100%;
  }
}

@media (max-width: 391px) {
  .typed {
    font-size: 15px !important;
    margin: 14px 0 !important;
  }
}
@media (min-width: 392px) and (max-width: 576px) {
  .typed {
    font-size: 16px !important;
    margin: 14px 0 !important;
  }
}
@media (min-width: 577px) and (max-width: 776px) {
  .typed {
    font-size: 23px !important;
    margin: 16px 0 !important;
  }
}
@media (min-width: 777px) and (max-width: 992px) {
  .typed {
    font-size: 28px !important;
    margin: 14px 0 !important;
  }
}
